import axios from "axios";
import { getToken } from '../config/tokenStorage';

const http = axios.create({
    baseURL: 'https://email-sender-prod.ap-southeast-1.elasticbeanstalk.com/api/v1', 
    // baseURL: "http://localhost:3000/api/v1",
});
  
http.interceptors.request.use((config) => {
    const token = getToken(); 
    if (token){
      config.headers.Authorization = `Bearer ${token}`
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export function getAllEmails(){
    return http.get("/email/all/secured")
}
export function getEmailById(emailId){
    return http.get(`/email/secured/${emailId}`)
}

export function getAllSentEmails(page = 0, size = 10) {
    return http.get(`/email/all-sent/secured?page=${page}&size=${size}`);
}

export function getAllFailedEmails(page = 0, size = 10) {
    return http.get(`/email/all-failed/secured?page=${page}&size=${size}`);
}

export function sendQueuedEmails(email) {
    return http.post("/email/send/secured", email);
}
export function sendFailedEmail(emailId){
    return http.post(`/email/send-failed/secured/${emailId}`);
}

export function resendEmail(emailId){
    return http.post(`/email/resend/secured/${emailId}`);
}
