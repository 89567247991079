import React, { useState } from "react";
import { sendQueuedEmails } from "../../../services/EmailService";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const EmailForm = () => {
  const [emailData, setEmailData] = useState({
    emailFrom: "",
    emailTo: "",
    emailCc: "",
    emailBcc: "",
    emailSubject: "",
    emailBody: "",
  });

  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true); 

    sendQueuedEmails(emailData)
      .then((response) => {
        console.log("Email sent successfully");
        toast.success("Email sent successfully");
        setLoading(false); 
        setEmailData({
          emailFrom: "",
          emailTo: "",
          emailCc: "",
          emailBcc: "",
          emailSubject: "",
          emailBody: "",
        });
      })
      .catch((error) => {
        console.log("Error sending email:", error);
        toast.error("Error sending email");
        setLoading(false); 
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmailData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Grid container justifyContent="center" sx={{ marginBottom: "2rem" }}>
        <Grid item xs={10} sm={10} md={8} lg={8} xl={6}>
          <Card variant="outlined" sx={{ boxShadow: 10 }}>
            <CardContent>
              <Typography
                align="center"
                mb={5}
              >
                FOR TESTING
              </Typography>
              <Typography variant="body2" gutterBottom align="center">
                For multiple recipients: separate email addresses with a
                semi-colon (;).
              </Typography>

              <form onSubmit={handleFormSubmit}>
                <Box mb={5}>
                  <TextField
                    id="email-from"
                    name="emailFrom"
                    label="From:"
                    variant="outlined"
                    fullWidth
                    value={emailData.emailFrom}
                    onChange={handleInputChange}
                  />
                </Box>
                <Box mb={5}>
                  <TextField
                    id="email-to"
                    name="emailTo"
                    label="To:"
                    variant="outlined"
                    fullWidth
                    value={emailData.emailTo}
                    onChange={handleInputChange}
                    required
                  />
                </Box>
                <Box mb={5}>
                  <TextField
                    id="email-cc"
                    name="emailCc"
                    label="Cc:"
                    variant="outlined"
                    fullWidth
                    value={emailData.emailCc}
                    onChange={handleInputChange}
                  />
                </Box>
                <Box mb={5}>
                  <TextField
                    id="email-bcc"
                    name="emailBcc"
                    label="Bcc:"
                    variant="outlined"
                    fullWidth
                    value={emailData.emailBcc}
                    onChange={handleInputChange}
                  />
                </Box>
                <Box mb={5}>
                  <TextField
                    id="email-subject"
                    name="emailSubject"
                    label="Email Subject"
                    variant="outlined"
                    fullWidth
                    value={emailData.emailSubject}
                    onChange={handleInputChange}
                    required
                  />
                </Box>
                <Box mb={5}>
                  <TextField
                    id="email-body"
                    name="emailBody"
                    label="Email Body"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={10}
                    value={emailData.emailBody}
                    onChange={handleInputChange}
                    required
                  />
                </Box>
                <Grid container justifyContent="center" mt={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    disabled={loading}
                    startIcon={loading && <CircularProgress size={20} />}
                  >
                    {loading ? "Sending..." : "Send"}
                  </Button>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <ToastContainer /> 
    </>
  );
};

export default EmailForm;
